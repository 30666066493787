import styled from "styled-components";

export const FragmentsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 250px);
  justify-content: center;
  grid-gap: 16px;
`;

export const FragmentStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #C4C4C4;
  width: 250px;


  h6 {
    text-align: center;
    font-weight: 600;
    margin: 0;
  }

  .group-indicator {
    font-size: 12px;
    text-align: center;
  }

  .fragment-body {
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .fragment-actions {
    margin-top: auto;
    gap: 8px;
    justify-content: end;
    background: white;
    button {
      padding: 8px 16px;
      border-radius: 8px;
      border: solid 1px;
      &:first-child {
        background-color: var(--primary-color);
        border-color: var(--primary-color);
        color: white;
      }
      &:last-child {
        background-color: white;
        border-color: #C4C4C4;
      }
    }

  }



`;

export const FragmentFormContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
  position: fixed;
  top: 50%;
  left: ${props => props.isOpen ? "50%" : "-100%"};
  transform: translate(-50%, -50%);
  overflow: auto;
  z-index: 101;
  background: ${props => props.isOpen ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0)"};

  form {
    background: white;
    padding: 24px;
    max-width: 400px;
  }

  .fragment-actions {
    margin-top: auto;
    gap: 8px;
    justify-content: end;
    background: white;
    button {
      padding: 8px 16px;
      border-radius: 8px;
      border: solid 1px;
      background-color: white;
        border-color: #C4C4C4;      
    }

  }


  .orange-button {
    padding: 8px 16px;
      border-radius: 8px;
      border: solid 1px;
    background-color: var(--primary-color);
        border-color: var(--primary-color);
        color: white;
  }

`;
