import React from "react";
import { FragmentStyle } from "../styles";
import TrashIcon from "../../../assets/Images/trashIcon.svg";
import { MDBTooltip } from "mdbreact";
import Swal from "sweetalert2";
import Petition from "../../../utils/Petition";

const FragmentCard = ({ _id, name, body, groups, handleEdit, reloadData }) => {
  const deleteFragment = () => {
    const requestData = {id: _id};
    const endpoint = `/fragments/delete/${_id}`;
    Petition.delete(endpoint, 
      () => {
        Swal.fire({
          title: "El fragmento se ha eliminado correctamente.",
          icon: "warning",
          timer: 2000,
        });
        Petition.logs({data: requestData, endpoint, type: "Delete", register: "Fragmentos: Eliminar" });
        reloadData();
      },
      () => {
        Swal.fire({
          title: `Ha ocurrido un error al eliminar el fragmento, intente nuevamente.`,
          icon: "error",
          timer: 2000,
        });
      }
    );
  };

  const handleDelete = () => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Conservar fragmento",
      focusCancel: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteFragment(_id);
      }
    });
  };

  const renderGroups = () => {
    return groups.map((groupObj, index) => (
      <span key={index} className="group-indicator">
        {groupObj.group}/{groupObj.subgroups.join(", ")}
      </span>
    ));
  };

  return (
    <FragmentStyle>
      <h6>{name}</h6>
      {renderGroups()}
      <MDBTooltip domElement tag="span" placement="top">
        <span className="fragment-body">{body}</span>
        <div className="p-2">{body}</div>
      </MDBTooltip>
      <div className="d-flex fragment-actions">
        <button onClick={() => handleEdit()}>Modificar</button>
        <button onClick={() => handleDelete()}>
          <img src={TrashIcon} alt="Eliminar" />
        </button>
      </div>
    </FragmentStyle>
  );
};

export default FragmentCard;

